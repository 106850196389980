<template>
  <div class="col-12 m-0 p-0 bordered">
    <div class="col-12 mb-1 p-0 text-center">
      {{`Guest ${index+1}`}}
    </div>
    <div class="col-12 mb-1 p-0">
      Name
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="name" type="text" :optional="false" @updated="changeAnswer('name', $event)">
      </form-element>
    </div>
    <div class="col-12 mb-1 p-0">
      Surname
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="surname" type="text" :optional="false" @updated="changeAnswer('surname', $event)">
      </form-element>
    </div>
    <div class="col-12 mb-1 p-0">
      Email
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="email" type="text" :optional="false" @updated="changeAnswer('email', $event)">
      </form-element>
    </div>
  </div>
</template>
<script>
const FormElement = () => import('@/components/FormElement')
export default {
  name: 'SecondaryGuestQuestionnaire',
  props: {
    background: {
      value: String,
      default: null
    },
    disabled: {
      value: Boolean,
      default: false
    },
    index: {
      value: Number,
      required: true
    }
  },
  components: {
    FormElement
  },
  data: () => ({
    name: null,
    surname: null,
    email: null
  }),
  methods: {
    changeAnswer (field, value) {
      if (typeof value !== 'undefined') {
        this[field] = value
      }
      this.$emit('updated', {
        index: this.index,
        details: {
          name: this.name,
          surname: this.surname,
          email: this.email
        }
      })
    }
  }
}
</script>
<style scoped>
.bordered {
  border: 1px dotted;
  border-color: inherit;
  padding: 3px !important;
  margin-bottom: 5px !important;
}
</style>
